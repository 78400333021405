/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {toast} from "react-toastify";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const StepAdminAncare = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [createdPhone, setCreatedPhone] = useState('');
  const [createdScanCode, setCreatedScanCode] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (phone && secretCode) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [phone, secretCode])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1 style={{
            color: '#6858ab',
            fontSize: '30px',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'THÔNG TIN ĐĂNG KÝ ANCARE'
              :
              'REGISTRATION INFORMATION ANCARE'
            }
          </h1>
          <h3
            style={{
              color: '#ff6c00',
              fontFamily: 'UTM-APTIMABOLD',
              fontSize: '22px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Xin vui lòng nhập thông tin chính xác và đầy đủ của bạn'
              :
              'Please enter your correct and complete information'
            }

          </h3>
          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Tên'
              :
              'Name'
            }
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Số điện thoại'
              :
              'Phone number'
            }
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA'}}>
            {LANGUAGE === 'VI'
              ?
              'Mật khẩu'
              :
              'Password'
            }
          </h3>
          <input
            value={secretCode}
            onChange={(e) => setSecretCode(e.target.value)}
            type="password"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <div
            style={{
              height: '150px',
              paddingTop: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              axios.post(`${BACKEND_URL}/character/admin-define-by-code-ancare`, {
                phone: phone,
                name: name,
                scan_code: secretCode,
              }).then(res => res.data)
                .then(resData => {
                  if (resData.error_code === 1) {
                    setErrorMsg('')
                    setIsCorrect(false)
                    setIsCreated(true)
                    setCreatedPhone(resData.data.phone)
                    setCreatedScanCode(resData.data.scan_code)
                  } else {
                    setErrorMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                  }
                })
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'KÍCH HOẠT'
                :
                'ACTIVATE'
              }
            </span>
          </button>


          <div
            style={{
              display: isCreated ? 'block' : 'none',
              marginTop: '1000px',
              marginBottom: '300px',
            }}
          >
            <h3
              style={{
                marginTop: '40px',
                color: '#fd3960',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {name}
            </h3>
            <h3
              style={{
                marginTop: '40px',
                color: '#f87c1e',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {createdPhone}
            </h3>
            <h3
              style={{
                marginTop: '20px',
                color: '#1aa5ea',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {createdScanCode}
            </h3>
          </div>


        </div>


      </div>

    </div>
  )
}

export {StepAdminAncare}
