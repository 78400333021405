/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {toast} from "react-toastify";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const StepAdminAncareShort = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [createdPhone, setCreatedPhone] = useState('');
  const [createdScanCode, setCreatedScanCode] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (phone) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [phone])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1 style={{
            color: '#6858ab',
            fontSize: '30px',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'THÔNG TIN ĐĂNG KÝ ANCARE-SHORT'
              :
              'REGISTRATION INFORMATION ANCARE-SHORT'
            }
          </h1>
          <h3
            style={{
              color: '#ff6c00',
              fontFamily: 'UTM-APTIMABOLD',
              fontSize: '22px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Xin vui lòng nhập thông tin chính xác và đầy đủ của bạn'
              :
              'Please enter your correct and complete information'
            }

          </h3>
          <div
            style={{
              height: '40px',
            }}
          >
          </div>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Tên'
              :
              'Name'
            }
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Số điện thoại'
              :
              'Phone number'
            }
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <div
            style={{
              height: '80px',
              paddingTop: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: "30px 0px 30px 20px",
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={(e) => {
              if (!window.confirm('Xác nhận đăng ký?')) {
                return false
              }
              const rect = e.target.getBoundingClientRect();
              // Tính tọa độ tương đối của điểm click so với div
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              const width = rect.width;
              const height = rect.height;

              // từ rộng, cao ra code
              // rộng chia 6, cao chia 3
              let secretCode

              if (y <= height/3) {
                // dong 1
                if (x <= width * 1 / 6) {
                  secretCode = '190000000'
                } else if (x > width * 1 / 6 && x <= width * 2 / 6) {
                  secretCode = '120000000'
                } else if (x > width * 2 / 6 && x <= width * 3 / 6) {
                  secretCode = '210000000'
                } else if (x > width * 3 / 6 && x <= width * 4 / 6) {
                  secretCode = '230000000'
                } else if (x > width * 4 / 6 && x <= width * 5 / 6) {
                  secretCode = '320000000'
                } else if (x > width * 5 / 6 && x <= width * 6 / 6) {
                  secretCode = '340000000'
                }
              } else if (y > height/3 && y <= height * 2 / 3) {
                // dong 2
                if (x <= width * 1 / 6) {
                  secretCode = '430000000'
                } else if (x > width * 1 / 6 && x <= width * 2 / 6) {
                  secretCode = '450000000'
                } else if (x > width * 2 / 6 && x <= width * 3 / 6) {
                  secretCode = '540000000'
                } else if (x > width * 3 / 6 && x <= width * 4 / 6) {
                  secretCode = '560000000'
                } else if (x > width * 4 / 6 && x <= width * 5 / 6) {
                  secretCode = '650000000'
                } else if (x > width * 5 / 6 && x <= width * 6 / 6) {
                  secretCode = '670000000'
                }
              } else {
                // dong 3
                if (x <= width * 1 / 6) {
                  secretCode = '760000000'
                } else if (x > width * 1 / 6 && x <= width * 2 / 6) {
                  secretCode = '780000000'
                } else if (x > width * 2 / 6 && x <= width * 3 / 6) {
                  secretCode = '870000000'
                } else if (x > width * 3 / 6 && x <= width * 4 / 6) {
                  secretCode = '890000000'
                } else if (x > width * 4 / 6 && x <= width * 5 / 6) {
                  secretCode = '980000000'
                } else if (x > width * 5 / 6 && x <= width * 6 / 6) {
                  secretCode = '910000000'
                }
              }

              setIsCorrect(false)
              axios.post(`${BACKEND_URL}/character/admin-define-by-code-ancare`, {
                phone: phone,
                name: name,
                scan_code: secretCode,
              }).then(res => res.data)
                .then(resData => {
                  if (resData.error_code === 1) {
                    setErrorMsg('')
                    setIsCorrect(false)
                    setIsCreated(true)
                    setCreatedPhone(resData.data.phone)
                    setCreatedScanCode(resData.data.scan_code)
                  } else {
                    setErrorMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                  }
                })
            }}
          >
            <span
              style={{
                fontSize: '44px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 26,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'LOG IN'
                :
                'ACTIVATE'
              }
            </span>
          </button>


          <div
            style={{
              display: isCreated ? 'block' : 'none',
              marginTop: '1000px',
              marginBottom: '300px',
            }}
          >
            <h3
              style={{
                marginTop: '40px',
                color: '#fd3960',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {name}
            </h3>
            <h3
              style={{
                marginTop: '40px',
                color: '#f87c1e',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {createdPhone}
            </h3>
            <h3
              style={{
                marginTop: '20px',
                color: '#1aa5ea',
                fontFamily: 'UTM-APTIMABOLD',
                fontSize: '46px',
                textAlign: 'center'
              }}
            >
              {createdScanCode}
            </h3>
          </div>


        </div>


      </div>

    </div>
  )
}

export {StepAdminAncareShort}
