import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {StepZero} from "../pages/StepZero";
import {StepZero1} from "../pages/StepZero1";
import {StepOne} from "../pages/StepOne";
import {StepTwo} from "../pages/StepTwo";
import {StepTwo1} from "../pages/StepTwo1";
import {StepTwo2} from "../pages/StepTwo2";
import {Package} from "../pages/Package";
import {StepTwo3} from "../pages/StepTwo3";
import {StepThree} from "../pages/StepThree";
import {StepFour} from "../pages/StepFour";
import {StepFive} from "../pages/StepFive";
import {StepSix} from "../pages/StepSix";
import {StepSeven} from "../pages/StepSeven";
import {StepEight} from "../pages/StepEight";
import {StepAdmin} from "../pages/StepAdmin";
import {StepAdminAncare} from "../pages/StepAdminAncare";
import {StepAdminAncareShort} from "../pages/StepAdminAncareShort";
import {Result} from "../pages/Result";
import {Tutorial} from "../pages/Tutorial";
import {ReportTutorial} from "../pages/ReportTutorial";
import {Intro} from "../pages/Intro";
import {Intro1} from "../pages/Intro1";
import {Intro2} from "../pages/Intro2";
import {Donate} from "../pages/Donate";
import {Donate2} from "../pages/Donate2";
import {Donate3} from "../pages/Donate3";
import {NoteScan} from "../pages/NoteScan";
import {NoteLogin} from "../pages/NoteLogin";
import {CreateSale} from "../pages/CreateSale";

const Routes: FC = () => {
  return (
    <Switch>
      {/*<Route path='/step-zero' component={StepZero} />*/}
      {/*<Route path='/note-scan' component={NoteScan} />*/}
      {/*<Route path='/note-login' component={NoteLogin} />*/}
      {/*<Route path='/tutorial' component={Tutorial} />*/}
      {/*<Route path='/report-tutorial' component={ReportTutorial} />*/}
      <Route path='/intro' component={Intro} />
      {/*<Route path='/intro1' component={Intro1} />*/}
      {/*<Route path='/intro2' component={Intro2} />*/}
      {/*<Route path='/donate' component={Donate} />*/}
      {/*<Route path='/donate2' component={Donate2} />*/}
      {/*<Route path='/donate3' component={Donate3} />*/}
      {/*<Route path='/step-zero1' component={StepZero1} />*/}
      {/*<Route path='/step-one' component={StepOne} />*/}
      {/*<Route path='/step-two' component={StepTwo} />*/}
      {/*<Route path='/step-two1' component={StepTwo1} />*/}
      {/*<Route path='/step-two2' component={StepTwo2} />*/}
      {/*<Route path='/package' component={Package} />*/}
      {/*<Route path='/step-two3' component={StepTwo3} />*/}
      {/*<Route path='/step-three' component={StepThree} />*/}
      {/*<Route path='/step-four' component={StepFour} />*/}
      {/*<Route path='/step-five' component={StepFive} />*/}
      {/*<Route path='/step-six' component={StepSix} />*/}
      {/*<Route path='/step-seven' component={StepSeven} />*/}
      {/*<Route path='/step-eight' component={StepEight} />*/}
      {/*<Route path='/result' component={Result} />*/}
      {/*<Route path='/create-sale' component={CreateSale} />*/}
      <Route path='/admin' component={StepAdmin} />
      <Route path='/admin-ancare' component={StepAdminAncare} />
      <Route path='/admin-ancare-short' component={StepAdminAncareShort} />
      <Redirect to='/intro' />
    </Switch>

  )
}

export {Routes}
